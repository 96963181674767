<template>
  <div class="paymentDashboard" :class="'dashboardType--' + dashboardType">
    <vue-scroll :ops="ops">
      <div class="paymentDashboard__nav">
        <div class="paymentDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Pay</span>
        </div>
        <div class="paymentDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Get paid</span>
        </div>
      </div>
      <div class="paymentDashboard__content">
        <div class="paymentDashboard__content-item js-tabContentItem">
          <PaymentDashboardContent :type="'pay'"/>
        </div>
        <div class="paymentDashboard__content-item js-tabContentItem">
          <PaymentDashboardContent/>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import PaymentDashboardContent from '@/components/dashboard/PaymentDashboardContent/PaymentDashboardContent'
  import './Payment.styl'

  export default {
    props: ['dashboardType'],
    name: 'Payment',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
        document.querySelectorAll('.js-acc-content').forEach((content) => {
          this.$root.animations.slideUp(content)
        })
      },
    },
    components: {
      PaymentDashboardContent,
    },
  }
</script>
