<template>
  <modal name="paymentCardDetail" transition="pop-out">
    <div class="paymentDashboardCard modal" id="paymentCardDetail">
      <div class="paymentDashboardCard__inner">
        <!-- <div class="paymentDashboardCard__preview"> -->
          <!-- <div class="paymentDashboardCard__preview-img">
            <img v-if="dataForPopup.creditCardBrand === 'Visa'" src="@/assets/img/dashboard/visa.png" alt=""/>
            <img v-else src="@/assets/img/dashboard/mastercard.png" alt=""/>
          </div> -->
          <!-- <div class="paymentDashboardCard__preview-number">{{dataForPopup.creditCardLast4}}</div> -->
        <!-- </div> -->
        <div class="paymentDashboardCard__info">
          <!-- <div class="paymentDashboardCard__info-header">
            <div class="paymentDashboardCard__title">{{dataForPopup.paymentType}}</div>
            <div class="paymentDashboardCard__subtitle">Debit card</div>
          </div> -->
          <div class="paymentDashboardCard__info-list">
<!--            <div class="paymentDashboardCard__info-list-item">-->
<!--              <div class="paymentDashboardCard__info-list-item-title">Card holder:</div>-->
<!--              <div class="paymentDashboardCard__info-list-item-value">{{dataForPopup.cardHolder}}</div>-->
<!--            </div>-->
<!--            <div class="paymentDashboardCard__info-list-item">-->
<!--              <div class="paymentDashboardCard__info-list-item-title">Billing address:</div>-->
<!--              <div class="paymentDashboardCard__info-list-item-value">{{dataForPopup.billingAddress}}</div>-->
<!--            </div>-->
            <div class="paymentDashboardCard__info-list-item">
              <div class="paymentDashboardCard__info-list-item-title">Brand:</div>
              <div class="paymentDashboardCard__info-list-item-value">{{dataForPopup.creditCardBrand}}</div>
            </div>
            <div class="paymentDashboardCard__info-list-item">
              <div class="paymentDashboardCard__info-list-item-title">Number:</div>
              <div class="paymentDashboardCard__info-list-item-value">************{{dataForPopup.creditCardLast4}}</div>
            </div>
            <div class="paymentDashboardCard__info-list-item">
              <div class="paymentDashboardCard__info-list-item-title">Expiration date:</div>
              <div class="paymentDashboardCard__info-list-item-value">{{dataForPopup.creditCardExpMonth}}/{{dataForPopup.creditCardExYear}}</div>
            </div>
          </div>
        </div>
        <div class="paymentDashboardCard__bottom" style="justify-content: flex-end">
<!--          <div class="paymentDashboardCard__link">Update card data</div>-->
          <span
            class="paymentDashboardCard__link paymentDashboardCard__link--remove js-popup"
            @click="showQuestion"
          >Remove card</span>
        </div>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('paymentCardDetail')">
          <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './PaymentCardPopup.styl'

  export default {
    props: ['dataForPopup'],
    name: 'PaymentCardPopup',
    methods: {
      showQuestion (item) {
        this.$modal.hide('paymentCardDetail')
        this.$modal.show('QuestionPopupPayment')
        this.$store.commit('insertPaymentToRemove', this.dataForPopup.id)
      },
    },
    components: {
        SvgIcon,
      },
  }
</script>
