<template>
  <div class="paymentDashboard__inner">
    <div v-if="type === 'pay'" class="paymentDashboard__left">
      <div class="paymentDashboard__title">Credit or debit card information</div>
      <form
        class="form paymentDashboard__list"
      >
        <div
          v-for="item of payment"
          :key="item.id"
          class="paymentDashboard__list-item"
        >
          <label class="form__label form__label--radio">
            <input
              class="form__radio"
              type="radio"
              v-model="card"
              :value="item.id"
            />
            <i class="form__radio-icon"></i>
            <!-- <span class="form__radio-img">
              <img v-if="item.creditCardBrand === 'Visa'" src="@/assets/img/dashboard/visa.png" alt=""/>
              <img v-else-if="item.creditCardBrand === 'MasterCard'" src="@/assets/img/dashboard/mastercard.png" alt=""/>
              <SvgIcon v-else name="credit-card"/>
            </span> -->
            <span class="form__radio-box">{{ item.creditCardBrand }} ending in {{item.creditCardLast4.slice(-4)}}</span>
          </label>
          <span
            class="paymentDashboard__list-link js-popup"
            @click="getCardInfo(item.id)"
          >
            <span class="text">See detail</span>
            <span class="icon"><SvgIcon name="info"/></span>
          </span>
        </div>

      </form>
      <div class="paymentDashboard__add stripe-new-card-payment js-accordion-item">
        <div class="paymentDashboard__add-header js-acc-title" @click="togglePayment">
          <span class="paymentDashboard__add-header-text">Add New Credit or Debit Card Payment Method</span>
          <span class="paymentDashboard__add-header-icon">
            <span class="paymentDashboard__add-header-icon-minus"><SvgIcon name="minus"/></span>
            <span class="paymentDashboard__add-header-icon-plus"><SvgIcon name="add"/></span>
          </span>
        </div>
        <div class="paymentDashboard__add-content js-acc-content">
          <div class="paymentDashboard__form">
            <form
              class="form form--addNewCardMethod js-addNewCardMethod"
              :class="[{'loading' : loadingCard}]"
              @submit.prevent="submitHandlerCard"
            >
              <div class="form__radioGroup">
                <label class="form__label form__label--radio">
                  <input
                    class="form__radio"
                    type="radio"
                    v-model="typeCard"
                    value="credit"
                  />
                  <span class="form__radio-box">Card</span>
                </label>
              </div>
              <div class="form__textGroup">
                <StripeSaveCardForm @save-card-status="onSaveCard" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <PaymentCardPopup :dataForPopup="dataForPopup"/>
      <QuestionPopup
        :name="'QuestionPopupPayment'"
        :title="'The payment will be removed'"
        :text="'Are you sure?'"
        @remove="remove"
      />
    </div>
    <div class="paymentDashboard__right">
      <div class="paymentDashboard__title">Wire - bank information</div>
      <form class="form paymentDashboard__list">
<!--        <div class="paymentDashboard__list-item">-->
<!--          <label class="form__label form__label&#45;&#45;radio">-->
<!--            <input-->
<!--              class="form__radio"-->
<!--              type="radio"-->
<!--              v-model="bank"-->
<!--              value="XXXX"-->
<!--            />-->
<!--            <i class="form__radio-icon"></i>-->
<!--            <span class="form__radio-box">Account ending <b>in-XXXX</b></span>-->
<!--          </label>-->
<!--        </div>-->
<!--        <div class="paymentDashboard__list-item">-->
<!--          <label class="form__label form__label&#45;&#45;radio">-->
<!--            <input-->
<!--              class="form__radio"-->
<!--              type="radio"-->
<!--              v-model="bank"-->
<!--              value="YYYY"-->
<!--            />-->
<!--            <i class="form__radio-icon"></i>-->
<!--            <span class="form__radio-box">Account ending <b>in-YYYY</b></span>-->
<!--          </label>-->
<!--        </div>-->
<!--        <div class="paymentDashboard__list-item">-->
<!--          <label class="form__label form__label&#45;&#45;radio">-->
<!--            <input-->
<!--              class="form__radio"-->
<!--              type="radio"-->
<!--              v-model="bank"-->
<!--              value="ZZZZ"-->
<!--            />-->
<!--            <i class="form__radio-icon"></i>-->
<!--            <span class="form__radio-box">Account ending <b>in-ZZZZ</b></span>-->
<!--          </label>-->
<!--        </div>-->
      </form>
      <div class="paymentDashboard__add js-accordion-item">
        <div class="paymentDashboard__add-header js-acc-title" @click="togglePayment">
          <span class="paymentDashboard__add-header-text">Add New Wire-Bank Information</span>
          <span class="paymentDashboard__add-header-icon">
            <span class="paymentDashboard__add-header-icon-minus"><SvgIcon name="minus"/></span>
            <span class="paymentDashboard__add-header-icon-plus"><SvgIcon name="add"/></span>
          </span>
        </div>
        <div class="paymentDashboard__add-content js-acc-content">
          <div class="paymentDashboard__form">
            <form
              v-if="type === 'pay'"
              class="form form--addNewBankMethod"
              :class="[{'loading' : loadingBank}]"
              @submit.prevent="submitHandlerBank"
            >
              <label class="form__label form__label--address">
                <div class="form__caption">Holder name:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="accountHolderName"
                    autocomplete="off"
                    :disabled="loadingBank"
                  />
                </div>
              </label>
              <div class="form__labelGroup form__labelGroup--radio">
                <div class="form__caption">Holder type</div>
                <div class="form__radioGroup">
                  <label class="form__label form__label--radio">
                    <input
                      class="form__radio"
                      type="radio"
                      v-model="accountHolderType"
                      value="individual"
                      :disabled="loadingBank"
                    />
                    <span class="form__radio-icon"></span>
                    <span class="form__radio-box">Individual</span>
                  </label>
                  <label class="form__label form__label--radio">
                    <input
                      class="form__radio"
                      type="radio"
                      v-model="accountHolderType"
                      value="company"
                      :disabled="loadingBank"
                    />
                    <span class="form__radio-icon"></span>
                    <span class="form__radio-box">Company</span>
                  </label>
                </div>
              </div>
              <label class="form__label form__label--address">
                <div class="form__caption">Routing number:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="routingNumber"
                    :disabled="loadingBank"
                  />
                </div>
              </label>
              <label class="form__label form__label--address">
                <div class="form__caption">Account number:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field js-numberOnly"
                    type="text"
                    v-model="accountNumber"
                    data-mask="000000000"
                    minlength="9"
                    autocomplete="off"
                    :disabled="loadingBank"
                  />
                </div>
              </label>
              <div class="form__label form__label--button" style="margin-bottom: 0">
                <Spinner/>
                <button class="form__button" :disabled="loadingBank">
                  <span class="text">Save</span>
                </button>
              </div>
            </form>

            <form
              v-else
              class="form form--addNewBankMethod"
            >
              <label class="form__label form__label--name">
                <div class="form__caption">Account holder name:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    autocomplete="off"
                    required="required"
                  />
                </div>
              </label>
              <label class="form__label form__label--name">
                <div class="form__caption">Bank name:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    autocomplete="off"
                    required="required"
                  />
                </div>
              </label>
              <label class="form__label form__label--address">
                <div class="form__caption">Bank address:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    required="required"
                  />
                </div>
              </label>
              <label class="form__label form__label--address">
                <div class="form__caption">Account address:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    required="required"
                  />
                </div>
              </label>
              <label class="form__label form__label--number">
                <div class="form__caption">ABA number:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field js-numberOnly"
                    type="text"
                    data-mask="000000000"
                    minlength="9"
                    autocomplete="off"
                    required="required"
                  />
                </div>
              </label>
              <label class="form__label form__label--number">
                <div class="form__caption">BIK/SWIFT:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    autocomplete="off"
                    required="required"
                  />
                </div>
              </label>
              <div class="form__label form__label--button" style="margin-bottom: 0">
                <Spinner/>
                <button class="form__button">
                  <span class="text">Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import PaymentCardPopup from '@/components/common/modals/PaymentCardPopup'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import StripeSaveCardForm from '@/components/Forms/StripeSaveCardForm.vue';
  import {stripeApi} from '@/api/stripe';

  export default {
    props: ['type'],
    name: 'PaymentDashboardContent',
    data: () => ({
      card: '',
      number: '',
      cvv: '',
      expiryMonth: '',
      expiryYear: '',
      typeCard: 'credit',
      bank: '',
      accountHolderName: '',
      accountHolderType: 'individual',
      routingNumber: '',
      accountNumber: '',
      dataForPopup: {},
      loadingCard: false,
      loadingBank: false,
      allCards: [],
    }),
    async created () {
      await this.fetchAllCards();
    },
    computed: {
      payment () {
        console.log(this.allCards)
        return this.allCards
      },
    },
    methods: {
      async fetchAllCards () {
        const response = await this.$store.dispatch('getPaymethods', true)
        this.allCards = response;
      },
      onSaveCard () {
        this.fetchAllCards();

        const item = document.querySelector('.stripe-new-card-payment .js-acc-content')
        this.animateItem(item);
      },
      animateItem (item) {
        document.querySelectorAll('.js-acc-content').forEach((content) => {
          if (item !== content) {
            this.$root.animations.slideUp(content)
          }
        })
        this.$root.animations.slideToggle(item)
      },
      togglePayment (event) {
        const item = event.currentTarget.closest('.js-accordion-item').querySelector('.js-acc-content')
        this.animateItem(item)
      },
      getCardInfo (id) {
        this.dataForPopup = this.payment.find(item => item.id === id)
        this.$modal.show('paymentCardDetail')
      },
      async remove () {
        this.loadingCard = true

        const result = await stripeApi.removeCard(this.dataForPopup.id)

        let message = {}

        if (result.data.isSuccessfull) {
          message = {
            title: 'Success!',
            text: 'Card removed successfully',
          }
        } else {
          console.log(result.data.message)
          message = {
            title: 'Something went wrong!',
            text: 'We apologize. If the problem persists, please email us at FLY@flyjets.com',
          }
        }
        this.$modal.show('ThanksPopup', {message})
        const response = await this.$store.dispatch('getPaymethods', true)
        this.allCards = response;

        this.loadingCard = false
      },
      async submitHandlerCard () {
        const data = {
          number: this.number,
          expiryMonth: this.expiryMonth,
          expiryYear: this.expiryYear,
          cvv: this.cvv,
          usedFor: 1,
        }

        this.loadingCard = true

        const result = await this.$store.dispatch('addCard', data)

        let message = {}

        if (result === true) {
          message = {
            title: 'Card Added',
            text: 'Your card has been added successfully',
          }
        } else {
          message = {
            title: 'Something went wrong!',
            text: 'We apologize. If the problem persists, please email us at FLY@flyjets.com',
          }
        }
        this.$modal.show('ThanksPopup', {message})
        const response = await this.$store.dispatch('getPaymethods', true)
        this.allCards = response;
        this.number = ''
        this.expiryMonth = ''
        this.expiryYear = ''
        this.cvv = ''
        document.querySelectorAll('.js-acc-content').forEach((content) => {
            this.$root.animations.slideUp(content)
        })

        this.loadingCard = false
      },
      async submitHandlerBank () {
        const data = {
          accountHolderName: this.accountHolderName,
          accountHolderType: this.accountHolderType,
          accountNumber: this.accountNumber,
          routingNumber: this.routingNumber,
          usedFor: 1,
        }

        this.loadingBank = true

        const result = await this.$store.dispatch('addBank', data)

        let message = {}

        if (result === true) {
          message = {
            title: 'Success!',
            text: 'Bank added successfully',
          }
        } else {
          message = {
            title: 'Something went wrong!',
            text: 'We apologize. If the problem persists, please email us at FLY@flyjets.com',
          }
        }
        this.$modal.show('ThanksPopup', {message})

        this.loadingBank = false
      },
    },
    mounted () {
      this.$root.fields.changeRadio('.form__radio')
    },
    components: {
      StripeSaveCardForm,
      SvgIcon,
      Spinner,
      PaymentCardPopup,
      QuestionPopup,
    },
  }
</script>
